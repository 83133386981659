.bulkbiltyhead tr td {
  font-size: 9px;
}

.watermark::after {
  content: '';
  position: absolute;
  top: 50mm;
  left: 60mm;
  transform: translate(-50%, -50%);
  width: 128px;
  height: 128px;
  background-image: url('https://res.cloudinary.com/dhtnk2eku/image/upload/v1727257642/tranports%20images%20important/xho3t66wgxyokodpebzo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.2;
  pointer-events: none;
}
